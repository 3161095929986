import { reactive } from 'vue-demi';
import {
  AnamneseAdvancedQuestionTypes,
  AnamneseCapability,
  AnamneseLicenceProblem,
  anamneseLicenceProblemChecks,
  CustomAnamneseQuestionTypes,
  IAnamneseCapabilityStatement,
  IQuestionnaire,
  IQuestionnaireElement,
} from '../../../../types';

export const anamneseLicenceStore = reactive({
  state: {
    capabilityStatement: null as null | IAnamneseCapabilityStatement,
    get maxQuestionnaireCount() {
      return this.capabilityStatement?.includedQuestionnaires ?? 0;
    },
  },
  questionnaireInGroupProblems(questionnaires: IQuestionnaire[], questionnaireIndex: number) {
    let elmProblems: AnamneseLicenceProblem[] = [];
    anamneseLicenceProblemChecks.questionnaireInGroupProblems(
      questionnaires,
      questionnaireIndex,
      elmProblems,
      this.state.capabilityStatement || undefined,
    );
    return elmProblems;
  },
  elementHasProblem(elm: IQuestionnaireElement) {
    let elmProblems: AnamneseLicenceProblem[] = [];
    anamneseLicenceProblemChecks.elementProblems(elm, elmProblems, this.state.capabilityStatement || undefined);
    return elmProblems.length > 0;
  },
  setCapabilityStatement(capabilityStatement: null | IAnamneseCapabilityStatement) {
    this.state.capabilityStatement = capabilityStatement;
  },
  isQuestionTypeAllowed(questionType: CustomAnamneseQuestionTypes) {
    if (!this.has.CUSTOM_QUESTIONS_ADVANCED_QUESITON_TYPES) {
      return !AnamneseAdvancedQuestionTypes.includes(questionType);
    }

    return true;
  },
  hasCapability(capability: AnamneseCapability) {
    return this.state.capabilityStatement?.capabilities.includes(capability);
  },
  // just a helper to make the code more readable
  // usage in template
  // <div v-if="licenceStore.has.CUSTOM_TEXT_FIELDS">
  get has() {
    let vals = Object.values(AnamneseCapability);
    return Object.fromEntries(vals.map(cap => [cap, this.hasCapability(cap)])) as Record<AnamneseCapability, boolean>;
  },
});

// @ts-ignore
window.anamneseLicenceStore = anamneseLicenceStore;
