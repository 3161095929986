import { reactive } from 'vue-demi';
import { colorBrightness } from '../../helpers/colorFunctions';

export const anamneseBrandingStore = reactive({
  state: {
    brandColor: '#123456',
    brandColor2: '#123456',
    get brandColorContrastText(): string {
      let brightness = colorBrightness(this.brandColor);
      let blackText = brightness > 160;
      return blackText ? '#000' : '#fff';
    },
    // use :style="cssVariables" binding to apply css variables
    get cssVariables(): any {
      return {
        '--brand-color': this.brandColor,
        '--brand-color2': this.brandColor2,
        '--brand-color-contrast-text': this.brandColorContrastText,
      };
    },
  },
  setBrandColor(brandColor: string) {
    this.state.brandColor = brandColor;
  },
  setBrandColor2(brandColor2: string) {
    this.state.brandColor2 = brandColor2;
  },
});
