import _ from 'lodash';
import { del as VueDelete, reactive, set as VueSet } from 'vue-demi';
import { CustomAnamneseQuestionTypes, IQuestionnaireQuestionAnswer, IQuestionnaireQuestion } from '../../../../types';
import { fakerDe } from '../../fakerDe';
import { isStringValidCharlyInput } from './anamnesePatientStore';

export const anamneseAnswerStore = reactive({
  state: {
    answers: {} as Record<string, IQuestionnaireQuestionAnswer>,
    signatureValid: false,
    questionnaireQuestions: [] as IQuestionnaireQuestion[],
  },
  setPrimaryAnswer(questionId: string | number, primaryAnswer: any) {
    VueSet(this.state.answers, questionId, {
      ...this.getAnswerForQuestion(questionId),
      primaryAnswer,
    });
  },
  setComment(questionId: string | number, comment: string) {
    VueSet(this.state.answers, questionId, {
      ...this.getAnswerForQuestion(questionId),
      comment,
    });
  },
  getAnswerForQuestion(questionId: string | number): IQuestionnaireQuestionAnswer | undefined {
    return this.state.answers[questionId];
  },
  getAnswers() {
    return this.state.answers;
  },
  getQuestionResponses(questions: IQuestionnaireQuestion[]) {
    return questions
      .filter(question => {
        let answer = this.state.answers[String(question.questionID)];
        let answered = answer && answer.primaryAnswer !== null && answer.primaryAnswer !== undefined;
        return answered;
      })
      .map(question => {
        let answer = this.state.answers[String(question.questionID)];

        return {
          question,
          answer,
        };
      });
  },
  clearAnswer(questionId: string | number) {
    VueDelete(this.state.answers, questionId);
  },
  clearAllAnswers() {
    this.state.answers = {};
  },
  setSignatureValid(valid: boolean) {
    this.state.signatureValid = valid;
  },
  demoAnswerAllUnansweredQuestions(questions: IQuestionnaireQuestion[]) {
    questions.forEach(question => {
      if (!this.hasQuestionValidAnswer(question)) {
        this.setPrimaryAnswer(question.questionID, this.getQuestionDemoPrimaryAnswer(question));

        if (
          question.valueType === CustomAnamneseQuestionTypes.BOOLEAN &&
          (question.valueTypeConfig.commentMandatory || Math.random() > 0.5)
        ) {
          this.setComment(question.questionID, fakerDe.lorem.sentence());
        }
      }
    });
  },
  hasEveryQuestionValidAnswer(questions: IQuestionnaireQuestion[]) {
    return questions.every(question => this.hasQuestionValidAnswer(question));
  },
  hasQuestionValidAnswer(question: IQuestionnaireQuestion) {
    if (question.valueType === CustomAnamneseQuestionTypes.INVOICE_LIST_SUMMARY) {
      return true;
    }

    let answer = this.getAnswerForQuestion(question.questionID);
    if (question.isCharlyQuestion) {
      let strings = answer?.comment + answer?.primaryAnswer;

      if (isStringValidCharlyInput(strings).valid === false) {
        return false;
      }
    }

    if (
      question.optional ||
      (question.valueType === CustomAnamneseQuestionTypes.BOOLEAN && question.valueTypeConfig.renderCheckboxStyle)
    ) {
      return true;
    }
    if (!answer || answer.primaryAnswer === null || answer.primaryAnswer === undefined) {
      return false;
    }

    if (question.valueType === CustomAnamneseQuestionTypes.INVOICE_LIST) {
      if (question.valueTypeConfig.teethMandatory) {
        if (answer.primaryAnswer?.some((item: any) => !item.teeth)) {
          return false;
        }
      }

      return answer.primaryAnswer?.length > 0;
    }

    if (question.valueType === CustomAnamneseQuestionTypes.BOOLEAN) {
      if (question.valueTypeConfig.commentMandatory) {
        let commentFieldOption = question.valueTypeConfig.commentFieldOption;
        let commentFieldVisible =
          commentFieldOption === 'always' ||
          (commentFieldOption === 'ifYes' && answer.primaryAnswer) ||
          (commentFieldOption === 'ifNo' && !answer.primaryAnswer);

        if (!commentFieldVisible) {
          return true;
        }

        return !!answer.comment && answer.comment.length > 0;
      }

      return true;
    }

    if (question.valueType === CustomAnamneseQuestionTypes.RATING) {
      return answer.primaryAnswer > 0;
    }

    if (question.valueType === CustomAnamneseQuestionTypes.TEXT) {
      return answer.primaryAnswer?.length > 0;
    }

    if (question.valueType === CustomAnamneseQuestionTypes.DATE) {
      return answer.primaryAnswer?.length > 0;
    }

    if (question.valueType === CustomAnamneseQuestionTypes.NUMBER) {
      return !Number.isNaN(+answer.primaryAnswer);
    }

    if (question.valueType === CustomAnamneseQuestionTypes.RANGE) {
      return answer.primaryAnswer >= 0 && answer.primaryAnswer <= 1;
    }

    if (question.valueType === CustomAnamneseQuestionTypes.EXISTING_SIGNATURE) {
      return answer.primaryAnswer >= 0;
    }

    if (question.valueType === CustomAnamneseQuestionTypes.SELECT) {
      if (question.valueTypeConfig.multiple) {
        return answer.primaryAnswer?.length > 0;
      } else {
        return answer.primaryAnswer !== null;
      }
    }

    return false;
  },
  getQuestionDemoPrimaryAnswer(question: IQuestionnaireQuestion) {
    const questionTypeDefaultPrimaryAnswer = {
      [CustomAnamneseQuestionTypes.BOOLEAN]: Math.random() > 0.7,
      [CustomAnamneseQuestionTypes.TEXT]: fakerDe.lorem.sentence(),
      [CustomAnamneseQuestionTypes.DATE]: fakerDe.date.birthdate().toISOString().split('T')[0],
      [CustomAnamneseQuestionTypes.NUMBER]: fakerDe.datatype.number({ min: 1, max: 20 }),
      [CustomAnamneseQuestionTypes.RANGE]: Math.random(),
      [CustomAnamneseQuestionTypes.EXISTING_SIGNATURE]: 0,
    };

    if (question.valueType in questionTypeDefaultPrimaryAnswer) {
      return questionTypeDefaultPrimaryAnswer[question.valueType as keyof typeof questionTypeDefaultPrimaryAnswer];
    }

    if (question.valueType === CustomAnamneseQuestionTypes.RATING) {
      return fakerDe.datatype.number({
        min: 1 / question.valueTypeConfig.maxCount,
        max: 1,
        precision: 1 / question.valueTypeConfig.maxCount,
      });
    }

    if (question.valueType === CustomAnamneseQuestionTypes.SELECT) {
      if (question.valueTypeConfig.multiple) {
        return _.sampleSize(
          question.valueTypeConfig.selectOptions,
          fakerDe.datatype.number({ min: 1, max: question.valueTypeConfig.selectOptions.length }),
        ).map((opt: any) => opt.name);
      } else {
        return _.sample(question.valueTypeConfig.selectOptions).name;
      }
    }

    throw new Error('No demo value for question type: ' + question.valueType);
  },
  getQuestionDefaultPrimaryAnswer(question: IQuestionnaireQuestion) {
    const questionTypeDefaultPrimaryAnswer = {
      [CustomAnamneseQuestionTypes.BOOLEAN]: null,
      [CustomAnamneseQuestionTypes.RATING]: 0,
      [CustomAnamneseQuestionTypes.TEXT]: '',
      [CustomAnamneseQuestionTypes.DATE]: '',
      [CustomAnamneseQuestionTypes.NUMBER]: '',
      [CustomAnamneseQuestionTypes.RANGE]: 0.5,
      [CustomAnamneseQuestionTypes.INVOICE_LIST]: [],
      [CustomAnamneseQuestionTypes.INVOICE_LIST_SUMMARY]: 1,
      [CustomAnamneseQuestionTypes.EXISTING_SIGNATURE]: -1,
    };

    if (question.valueType in questionTypeDefaultPrimaryAnswer) {
      return questionTypeDefaultPrimaryAnswer[question.valueType as keyof typeof questionTypeDefaultPrimaryAnswer];
    }

    if (question.valueType === CustomAnamneseQuestionTypes.SELECT) {
      if (question.valueTypeConfig.multiple) {
        return [];
      }
      return '';
    }

    throw new Error('No default value for question type: ' + question.valueType);
  },
});

// @ts-ignore
window.anamneseAnswerStore = anamneseAnswerStore;
